import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Home from './Pages/Home/Home';
import Treatments from './Pages/Treatments/Treatments';


function App() {


  useEffect(() => {
    document.addEventListener("wheel", function(event){
      const active : any = document?.activeElement;
      if (active?.type && active?.type === "number") active?.blur();
      
  });
  }, []);

  useEffect(() => {
    // For scrolling effects

  
    (async () => {
        const cacheNames = await caches?.keys();
        cacheNames && cacheNames.forEach(cacheName => caches.delete(cacheName));
    })();


  }, []);

  const exactProps = { exact: true };

  return  (
    <BrowserRouter>
      <Routes>
        
        <Route path="" element={<Home data={null} />} {...exactProps} />
        <Route path="tratamente" element={<Treatments />}/>
        <Route path="*" element={ <Navigate to="/" /> } />

        {/* <Route path="*" element={<Login />} /> */}


        {/* <Route path="*" element={<Navigate to="/" />} /> */}
       
      </Routes>
    </BrowserRouter>
  );
}

export default App;
