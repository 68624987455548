import React, { useCallback, useLayoutEffect, useState, useEffect, lazy, useRef, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import HeaderHome from '../../Components/Header_Home/Header_Home';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faAngleLeft, faAngleRight, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';

import Raluca from "../../imgs/raluca.svg";
import Bianca from "../../imgs/bianca.png";
import cabinet1 from "../../imgs/cabinet_1.jpeg";
import cabinet2 from "../../imgs/cabinet_2.jpg";
import cabinet3 from "../../imgs/cabinet_3.jpeg";
import cabinet4 from "../../imgs/cabinet_4.jpeg";
import cabinet5 from "../../imgs/cabinet_5.jpg";

import dentoZenLogo from "../../imgs/logo2.svg";
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import emailjs from '@emailjs/browser';


const Home = ({ data }: { data: any }) => {

    const [cookies, setCookies] = useCookies();
    const [doctorsIndex, setDoctorsIndex] = useState(0);

    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");

    function sendEmail(e : any) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
        emailjs.send('service_r98hm2q', 'template_x13lpgo', {
            from_name: name,
            from_email: email,
            from_phone: phone,
            to_name: "Claudiu"
        })
          .then((result) => {
              window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
      }
    


    const doctors = useMemo(() => {
        return [
            {
                name: "Dr. Tăutu Raluca",
                type: "Specialist parodontologie",
                image: Raluca,
                desc: "Dr. Raluca Tăutu, medicul nostru specializat în tratamente parodontologice, pasionată și de protetică dentară, ce va avea grijă ca nu doar dinții tăi să se păstreze zen, dar și starea ta de spirit. "
            },
            {
                name: "Cristescu Bianca",
                type: "Asistent medical generalist, studenă anul VI medicină dentară",
                image: Bianca,
                desc: "Cristescu Bianca, medicul nostru specializat în tratamente parodontologice, pasionată și de protetică dentară, ce va avea grijă ca nu doar dinții tăi să se păstreze zen, dar și starea ta de spirit."
            }
        ];
    }, []);


    let slideIndex = 1;

    useEffect(() => {
        showSlides(slideIndex);
    }, [document.getElementsByClassName("mySlides"), document.getElementsByClassName("dot")]);


    function plusSlides(n: number) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n: number) {
        showSlides(slideIndex = n);
    }

    function showSlides(n: number) {
        let i;
        let slides: any = document.getElementsByClassName("mySlides");
        if (n > slides.length) { slideIndex = 1 }
        if (n < 1) { slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            if (slides[i]?.style) slides[i].style.display = "none";
        }

        slides[slideIndex - 1].style.display = "block";
    }

    useEffect(() => {
        emailjs.init({
            publicKey: "uQcZ01TZEXdLXmQNL"
        });
    }, []);



    return (
        <div className="home-container">
            <HeaderHome data={data} activePage="home" />

            <div className="content">

                <div className="block-1">
                    <h2 className="title">
                        <span>Zâmbetul</span> tău <br />
                        de la A la <span className='swoosh'>ZEN.</span>

                    </h2>

                    <p className="description">{cookies.language === "en" ? "Come meet our team." : "Echipa DentoZen este un grup dedicat de profesioniști în domeniul stomatologiei care se străduiesc să ofere cele mai bune servicii și îngrijiri pacienților lor."}</p>

                    <button type="button" onClick={() => {

                    }}>
                        <p>{cookies?.language === "en" ? "Contact us" : "Contactează-ne"}</p>
                    </button>

                    {/* <p className="sm-text">{cookies?.language === "en" ? "No credit card required" : "Fără card de credit"}</p> */}
                </div>


                <h2 className="sm-title title" style={{ marginBottom: "5rem" }}>

                    Hai să cunoști echipa noastră.
                </h2>

                <div className="block-4">


                    <div className="block-container">
                        <h2>{doctors[doctorsIndex].name}</h2>
                        <p><b>{doctors[doctorsIndex].type}</b></p>


                        <p>
                            {doctors[doctorsIndex].desc}
                        </p>
                    </div>

                    <div className="shadow-line"></div>


                    {/* Avantajele noastre ca la bookr */}


                    <div className="block-container">
                        <div>
                            <FontAwesomeIcon style={{ opacity: doctorsIndex === 0 ? 0 : 1 }} icon={faAngleLeft as IconProp} className="arrow-icon" onClick={() => {
                                if (doctorsIndex === 0) return;
                                setDoctorsIndex(curr => curr - 1);
                            }} />
                            <img src={doctors[doctorsIndex].image} className="mockup" alt="mockup" />
                            <FontAwesomeIcon style={{ opacity: doctorsIndex === doctors.length - 1 ? 0 : 1 }} icon={faAngleRight as IconProp} className="arrow-icon" onClick={() => {
                                if (doctorsIndex === doctors.length - 1) return;
                                setDoctorsIndex(curr => curr + 1);
                            }} />
                        </div>

                    </div>
                </div>

                <div className="block-5 block-container">
                    <h2 className="title sm-title">
                        <span>Despre noi</span><br />
                    </h2>

                    <p className="description">Cabinetul stomatologic este dotat cu echipamente de ultimă generație, servicii complete și o echipă dedicată de medici, pasionați și cu experiență de lucru, care prin dobândirea experienței la numeroase congrese și cursuri, atât naționale cât și internaționale, te vor întâmpina mereu cu cele mai bune planuri de tratament.</p>
                  
                </div>

                <div className="block-5 block-container">
                    <h2 className="title sm-title">
                        <span>Galerie</span><br />
                    </h2>

                    <div className="slideshow-container">

                        <div className="mySlides fade">
                            <div className="numbertext">1 / 5</div>
                            <img src={cabinet1} alt="Poza cabinet"  style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">2 / 5</div>
                            <img src={cabinet2} alt="Poza cabinet 2" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">3 / 5</div>
                            <img src={cabinet3} alt="Poza cabinet 3" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">4 / 5</div>
                            <img src={cabinet4} alt="Poza cabinet 4" style={{ width: "100%" }} />
                        </div>

                        <div className="mySlides fade">
                            <div className="numbertext">5 / 5</div>
                            <img src={cabinet5} alt="Poza cabinet 5" style={{ width: "100%" }} />
                        </div>

                        <div className="prev" onClick={() => plusSlides(-1)}>
                            <FontAwesomeIcon icon={faAngleLeft as IconProp} className="angle-icon" />
                        </div>

                        <div className="next" onClick={() => plusSlides(1)}>
                            <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                        </div>

                    </div>
                </div>


                <div className="block-6 block-container">
                    <h2 className="title sm-title">Contactează-ne</h2>
                    <br />


                </div>

                <div className="contact">
                    <div className="contact-container">
                        <div className="contact-info">
                            <a href="https://www.google.com/maps?q=DentoZen,+apartament+40,+Strada+Foisorului+4+bloc+F1C+scara+2,+Bucure%C8%99ti+031172&ftid=0x40b1ff4b504ceb33:0x8630c01e5e827c19&entry=gps&lucs=,94224825,47071704,47069508,94214172,94218635,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTE3LjEuNjI5MjAYACCenQoqUSw5NDIyNDgyNSw0NzA3MTcwNCw0NzA2OTUwOCw5NDIxNDE3Miw5NDIxODYzNSw5NDIwMzAxOSw0NzA4NDMwNCw5NDIwODQ1OCw5NDIwODQ0N0ICUk8%3D&g_st=iw" target="_blank">
                                <div className="box">
                                    <FontAwesomeIcon icon={faAddressCard as IconProp} className="icon" />
                                    <div className="text-container">
                                        <h3>Adresa</h3>
                                        <p>București, Sector 3, strada Foișorului nr 4</p>
                                    </div>
                                </div>
                            </a>
                            
                            
                            <a href="tel:0729129629">
                                <div className="box">
                                    <FontAwesomeIcon icon={faPhone as IconProp} className="icon" />
                                    <div className="text-container">
                                        <h3>Telefon</h3>
                                        <p>0729 129 629</p>
                                    </div>
                                </div>
                            </a>
                         

                            <a href="mailto:smile@dentozen.ro">
                                <div className="box">
                                    <FontAwesomeIcon icon={faEnvelope as IconProp} className="icon" />
                                    <div className="text-container">
                                        <h3>Email</h3>
                                        <p>smile@dentozen.ro</p>
                                    </div>
                                </div>
                            </a>

                            <div className="box">
                                    <FontAwesomeIcon icon={faClock as IconProp} className="icon" />
                                    <div className="text-container">
                                        <h3>Program</h3>
                                        <p><span>Luni:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span>08:00 - 13:30</span></p>
                                        <p><span>Marti:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>14:30 - 20:00</span></p>
                                        <p><span>Miercuri:</span> &nbsp;<span>14:30 - 20:00</span></p>
                                        <p><span>Joi:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>08:00 - 13:30</span></p>
                                        <p><span>Vineri:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>14:30 - 20:00</span></p>
                                        <p><span>Sâmbătă:</span> &nbsp;<span>10:00 - 15:00</span></p>
                                        <p><span>Duminica:</span> &nbsp;<span>Închis</span></p>
                                    </div>
                            </div>
                          
                        </div>
                    </div>

                    <div className="contact-form">
                        <form action="">
                            <h2>Trimite mesaj</h2>
                            <div className="input-box">
                                <input aria-label='name' type="text" name="name" value={name} onChange={(e) => setName(e.target.value)}  id="name" />
                                <span>Numele dumneavoastra</span>
                            </div>

                          
                            <div className="input-box">
                                <input type="text" name="phone" aria-label='phone' id="phone" value={phone} onChange={(e) => setPhone(e.target.value)}  />
                                <span>Telefon</span>
                            </div>

                            <div className="input-box">
                                <input type="text" name="email" id="email" aria-label='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <span>Email (opțional)</span>
                            </div>

                            <div className="input-box" onClick={(e : any) => {
                                setEmail("");
                                setName("");
                                setPhone("");
                                sendEmail(e);
                            }}>
                                <input type="submit" value="Trimite" />
                            </div>
                        </form>
                    </div>
                </div>
{/* 
                <footer>

                    <div className="section">
                        <h2 className="section-title">Informatii de contact</h2>

                        <div className="section-container">
                            <i className="icon fas fa-phone"></i>
                            <p><b>Telefon:</b> +40 734 893 938</p>
                        </div>

                        <a href="tel:+40734893938" aria-label="Apeleaza">
                            <button>Apeleaza</button>
                        </a>


                        <div className="section-container">
                            <i className="icon far fa-map"></i>
                            <p><b>Adresa:</b> Strada Foisorului 4 bloc F1C scara 2, apartament 40 sector 3, Strada Foișorului 4, București 031172</p>
                        </div>

                        <a href="https://maps.app.goo.gl/aPWoqFqY6S58XDy76" aria-label="Vezi adresa" target="_blank">
                            <button>Vezi adresa</button>
                        </a>

                    </div>

                    <div className="section">
                        <h2 className="section-title">Program de lucru</h2>
                        <p><b>Luni:</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 09:00 - 16:00</p>
                        <p><b>Marti:</b> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;09:00 - 16:00</p>
                        <p><b>Miercuri:</b> &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Joi:</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Vineri:</b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;09:00 - 16:00</p>
                        <p><b>Sambata:</b>&nbsp; &nbsp; &nbsp;Închis</p>
                        <p><b>Duminica:</b>&nbsp; &nbsp; Închis</p>
                    </div>


                </footer> */}

                <div className="block-6 block-container">
                    <div className="google-maps-container">
                    <iframe title="Locatie Dentozen" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2849.540538239716!2d26.122709699999994!3d44.4220732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff4b504ceb33%3A0x8630c01e5e827c19!2sDentoZen!5e0!3m2!1sro!2sro!4v1717497228117!5m2!1sro!2sro" width="600" height="450" style={{ border: 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                    
                    </div>
                </div>


                <div className="fixed-icon-container">
                    <a href="tel:+40729129629" aria-label="Buton Telefon">
                        <FontAwesomeIcon icon={faPhoneAlt as IconProp} className="fixed-icon" />
                    </a>
                </div>


                <div className="block-7 final-block">
                    <div className="logo">

                    </div>
                    <h2 className="title last">Vino la <span>DentoZen.</span> <br /> Zambetul tau de la A la <span>ZEN.</span> </h2>

                    <img src={dentoZenLogo} alt="logo DentoZen" />

                    <p>© {new Date().getFullYear()}. All rights reserved.</p>
                    <p className="created-by">{cookies?.language === "en" ? "Created by Barbu Mihai." : "Creat de Barbu Mihai."}</p>
                </div>

            </div>

            {/* <Footer /> */}

        </div>
    );
};

export default Home;