import React, { useEffect, useState } from "react";
import HeaderHome from "../../Components/Header_Home/Header_Home";
import "./Treatments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faTooth } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Treatments = () => {


    const [activeIdx, setActiveIdx] = useState<number>(window.innerWidth < 900 ? -1 : 0);

    const changeActiveIdx = (newActiveIdx : number) => {
        if (window.innerWidth < 900) {
            console.log(activeIdx, newActiveIdx);
            if (activeIdx === newActiveIdx) setActiveIdx(-1);
            else setActiveIdx(newActiveIdx);
        } else setActiveIdx(newActiveIdx);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <div className="home-container">
            <HeaderHome activePage="treatments" data={null}/>
            <div className="content">
                <div className="block-1 block-container">
                    <h2 className="title">Tratamente</h2>
                    <div className="treatments">
                        <div className="names-container">

                            {window.innerWidth > 900 || activeIdx >= 0 ? <div className={`name-container ${activeIdx === 0 ? "active" : ""}`} onClick={() => changeActiveIdx(0)}>
                                <p>Servicii terapie, profilaxie, igienizare	</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 1 ? <div className={`name-container ${activeIdx === 1 ? "active" : ""}`} onClick={() => changeActiveIdx(1)}>
                                <p>Estetică dentară</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 2 ? <div className={`name-container ${activeIdx === 2 ? "active" : ""}`} onClick={() => changeActiveIdx(2)}>
                                <p>Odontoterapie (Plombe)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 3 ? <div className={`name-container ${activeIdx === 3 ? "active" : ""}`} onClick={() => changeActiveIdx(3)}>
                                <p>Ortodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 4 ? <div className={`name-container ${activeIdx === 4 ? "active" : ""}`} onClick={() => changeActiveIdx(4)}>
                                <p>Servicii endodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 5 ? <div className={`name-container ${activeIdx === 5 ? "active" : ""}`} onClick={() => changeActiveIdx(5)}>
                                <p>Servicii chirurgie mică, urgențe</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 6 ? <div className={`name-container ${activeIdx === 6 ? "active" : ""}`} onClick={() => changeActiveIdx(6)}>
                                <p>Pedodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 7 ? <div className={`name-container ${activeIdx === 7 ? "active" : ""}`} onClick={() => changeActiveIdx(7)}>
                                <p>Servicii chirurgie omf (medic specialist)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}
                            
                            {window.innerWidth > 900 || activeIdx >= 8 ? <div className={`name-container ${activeIdx === 8 ? "active" : ""}`} onClick={() => changeActiveIdx(8)}>
                                <p>Servicii parodontologie</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 9 ? <div className={`name-container ${activeIdx === 9 ? "active" : ""}`} onClick={() => changeActiveIdx(9)}>
                                <p>Servicii implantologie</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 10 ? <div className={`name-container ${activeIdx === 10 ? "active" : ""}`} onClick={() => changeActiveIdx(10)}>
                                <p>Servicii protetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 11 ? <div className={`name-container ${activeIdx === 11 ? "active" : ""}`} onClick={() => changeActiveIdx(11)}>
                                <p>Servicii estetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth > 900 || activeIdx >= 12 ? <div className={`name-container ${activeIdx === 12 ? "active" : ""}`} onClick={() => changeActiveIdx(12)}>
                                <p>Servicii pedodontie (Tratamente copii)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}
                        </div>

                        {activeIdx === 0 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="column">
                                    <p>Consultatie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Consultatie urgenta</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Consultatie medic specialist</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                       
                           
                            <div className="row">
                                <div className="column">
                                    <p>Detartraj</p>
                                </div>
                                <div className="column">
                                    <p>100 lei / arcadă</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Periaj profesional</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Air flow</p>
                                </div>
                                <div className="column">
                                    <p>100 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p><b>Pachet igienizare complet</b></p>
                                </div>
                                <div className="column">
                                    <p>Detartraj+periaj profesional+air flow (ambele arcade) 300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Fluorizare</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Modele de studiu</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 1 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="column">
                                    <p>Albire dentară la domiciliu ((include gutiere + 8 seringi gel Opalescence</p>
                                </div>
                                <div className="column">
                                    <p>700 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Albire dentară cu laser Biolase Epic X</p>
                                </div>
                                <div className="column">
                                    <p>1400 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 2 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coafaj</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit mică (1 suprafață)</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit medie (2 suprafețe)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit mare (3 suprafețe)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie compozit dinți frontali</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                           
                        </div> : null}

                        {activeIdx === 3 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="column">
                                    <p>Consultație primară medic specialist ortodonție si odf:</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Modele de studiu</p>
                                </div>
                                <div className="column">
                                    <p>80 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Fotografii de diagnostic ortodontic</p>
                                </div>

                                <div className="column">
                                    <p>80 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Stabilirea planului de tratament</p>
                                </div>

                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix metalic clasic</p>
                                </div>

                                <div className="column">
                                    <p>2400 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix metalic auto-ligaturant</p>
                                </div>

                                <div className="column">
                                    <p>3500 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix safir</p>
                                </div>

                                <div className="column">
                                    <p>4000 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix ceramic auto-ligaturant</p>
                                </div>

                                <div className="column">
                                    <p>3500 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix ceramic</p>
                                </div>

                                <div className="column">
                                    <p>3000 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat fix parțial metalic</p>
                                </div>

                                <div className="column">
                                    <p>1500 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat metalic Damon</p>
                                </div>

                                <div className="column">
                                    <p>4000 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat estetic Damon</p>
                                </div>

                                <div className="column">
                                    <p>5000 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aparat mobilizabil</p>
                                </div>

                                <div className="column">
                                    <p>1500 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Tratament cu alignere</p>
                                </div>

                                <div className="column">
                                    <p>4000-10000 lei / arcadă</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Disjunctor</p>
                                </div>

                                <div className="column">
                                    <p>1600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Arc transpalatinal</p>
                                </div>

                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Dispozitiv Nance</p>
                                </div>

                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Mască facială Delaire</p>
                                </div>

                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Bărbiță și capelină</p>
                                </div>

                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Dispozitive de mezializare/distalizare</p>
                                </div>

                                <div className="column">
                                    <p>1600 lei</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="column">
                                    <p>Sistem Benefit</p>
                                </div>

                                <div className="column">
                                    <p>5000 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Lip Bumper</p>
                                </div>

                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Trainer</p>
                                </div>

                                <div className="column">
                                    <p>800 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Activator</p>
                                </div>

                                <div className="column">
                                    <p>1000 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Menținător de spațiu</p>
                                </div>

                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Mini-implant ortodontic</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Control aparat fix</p>
                                </div>
                                <div className="column">
                                    <p>100 lei / arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Control aparat mobilizabil</p>
                                </div>
                                <div className="column">
                                    <p>100 lei / arcada <br /> 150 lei / 2 arcade</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Control lunar aparat fix (pacienti externi)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei / 2 arcade</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Control disjunctor</p>
                                </div>
                                <div className="column">
                                    <p>50 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Recolare bracket</p>
                                </div>
                                <div className="column">
                                    <p>50 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Înlocuire bracket metalic</p>
                                </div>
                                <div className="column">
                                    <p>80 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Înlocuire bracket fizionomic</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                            

                            <div className="row">
                                <div className="column">
                                    <p>Înlocuire inel</p>
                                </div>
                                <div className="column">
                                    <p>80 lei</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="column">
                                    <p>Recimentare inel</p>
                                </div>
                                <div className="column">
                                    <p>50 lei</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="column">
                                    <p>Îndepărtare aparat fix</p>
                                </div>
                                <div className="column">
                                    <p>300 lei / arcada</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Îndepărtare aparat fix la cerere</p>
                                </div>
                                <div className="column">
                                    <p>400 lei / arcada</p>
                                </div>
                            </div>


                            <div className="row">
                                <div className="column">
                                    <p>Gutieră de contenție</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Contenție fixă (retainer)</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Igienizare după îndepărtarea aparatelor</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Gutieră de corecție</p>
                                </div>
                                <div className="column">
                                    <p>350 lei / ambele arcade</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Reparație aparat mobil/funcțional</p>
                                </div>
                                <div className="column">
                                    <p>100 - 400 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Schimbare arc deteriorat/ fracturat</p>
                                </div>
                                <div className="column">
                                    <p>50 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Schimbare arc fizionomic ( la cererea pacientului)</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Profilaxie bracket-uri</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Tratament anti-inflamator gingival / ședința</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>


                        </div> : null}

                        {activeIdx === 4 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte pluriradiculari - premolar (2 canale)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extirpare vitala - dinte pluriradiculari - molar (3 canale)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal - dinte monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie de canal - dinte pluriradiculari - premolar (2 canale)</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie de canal - dinte pluriradiculari - molar (3 canale)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Dezobturare canal radicular</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Drenaj endodontic</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal provizorie - dinte monoradiculari</p>
                                </div>

                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Obturatie canal provizorie - dinte pluriradiculari</p>
                                </div>

                                <div className="column">
                                    <p>130 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 5 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti frontali monoradiculari</p>
                                </div>
                                <div className="column">
                                    <p>150 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti laterali pluriradiculari</p>
                                </div>
                                <div className="column">
                                    <p>250 lei - 300 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinte parodontotic</p>
                                </div>
                                <div className="column">
                                    <p>80 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie alveoloplastica</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie molar minte (erupt)</p>
                                </div>
                                <div className="column">
                                    <p>350 - 400 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Extractie molar minte (inclus)</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Gingivectomie cu gingivoplastie</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Incizie abces vestibular</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Imobilizare</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 6 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Sigilare</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Extractie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei - 200 lei</p>
                                </div>
                            </div>
                            
                        </div> : null}

                        {activeIdx === 7 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rezectie apicala dinti frontali</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rezectie apicala dinti laterali</p>
                                </div>
                                <div className="column">
                                    <p>450 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Regularizare creasta</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Premolarizare</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Decapusonare</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Chistectomie</p>
                                </div>
                                <div className="column">
                                    <p>350 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aditie os</p>
                                </div>
                                <div className="column">
                                    <p>100 - 300 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Lifting sinusal</p>
                                </div>
                                <div className="column">
                                    <p>500 euro</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 8 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Analiza microbiologica complexa</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Analiza microbiologica pe arcadă standard</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Chiuretaj subgingival pe hemiarcadă inchis</p>
                                </div>
                                <div className="column">
                                    <p>250 lei / arcadă</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Imobilizare</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Aplicare pasta antibiotic</p>
                                </div>
                                <div className="column">
                                    <p>50 lei / sedinta</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 9 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Sinus lift intern</p>
                                </div>
                                <div className="column">
                                    <p>400 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Sinus lift extern (include si aditie de os)</p>
                                </div>
                                <div className="column">
                                    <p>800 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aditie de os</p>
                                </div>
                                <div className="column">
                                    <p>150 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Descoperire implant</p>
                                </div>
                                <div className="column">
                                    <p>40 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Membrana colagen</p>
                                </div>
                                <div className="column">
                                    <p>50 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>PRF</p>
                                </div>
                                <div className="column">
                                    <p>100 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Explantare implant</p>
                                </div>
                                <div className="column">
                                    <p>100 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Tratament periimplantita</p>
                                </div>
                                <div className="column">
                                    <p>100 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Implant dentar INNO</p>
                                </div>
                                <div className="column">
                                    <p>500 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Bon de vindecare INNO</p>
                                </div>
                                <div className="column">
                                    <p>30 euro</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Bon protetic INNO</p>
                                </div>
                                <div className="column">
                                    <p>100 euro</p>
                                </div>
                            </div>

                        </div> : null}

                        {activeIdx === 10 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Ablatie lucrari dentare</p>
                                </div>
                                <div className="column">
                                    <p>50 lei / dinte</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coroana provizorie</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>RCR Metal</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>RCR Fibra de sticla</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza KEMENY</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Incrustatii ceramica</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana Definitiva acrilat</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Coroana turnata CR-CO</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana metalo - ceramica semifizionomica</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Coroana total fizionomica metalo - ceramica</p>
                                </div>
                                <div className="column">
                                    <p>600 lei</p>
                                </div>
                            </div>
                            
                            {/* HERE */}

                            <div className="row">
                                <div className="column">
                                    <p>Coroana ceramica - suport zirconiu</p>
                                </div>
                                <div className="column">
                                    <p>850 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza protetica acrilica</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza acrilica cu sisteme speciale (capse)</p>
                                </div>
                                <div className="column">
                                    <p>2000 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Proteza scheletata</p>
                                </div>
                                <div className="column">
                                    <p>2500 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Rebazare</p>
                                </div>
                                <div className="column">
                                    <p>250 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Reparatie proteza</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Inlocuire sisteme speciale</p>
                                </div>
                                <div className="column">
                                    <p>500 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 11 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fateta compozit (in cabinet)</p>
                                </div>
                                <div className="column">
                                    <p>300 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fateta ceramica</p>
                                </div>
                                <div className="column">
                                    <p>300 euro - PRET PROMO</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Albire dinti devitali</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Albire la domiciliu (include gutierele)</p>
                                </div>
                                <div className="column">
                                    <p>1200 lei Enlighten <br />
                                        800 lei Opalescence
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="column">
                                    <p>Aplicare bijuterii dentare</p>
                                </div>
                                <div className="column">
                                    <p>200 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        {activeIdx === 12 ? <div className="treatments-prices">
                            <div className="row">
                                <div className="column">
                                    <p>Denumire serviciu</p>
                                </div>
                                <div className="column">
                                    <p>Preț</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Fluorizare (ambele arcade)</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Extractie dinti temporari</p>
                                </div>
                                <div className="column">
                                    <p>100 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie coroana cu glassionomer</p>
                                </div>
                                <div className="column">
                                    <p>130 lei</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column">
                                    <p>Obturatie coroana cu compozit</p>
                                </div>
                                <div className="column">
                                    <p>150 lei</p>
                                </div>
                            </div>
                        </div> : null}

                        <div className="names-container">

                            {window.innerWidth <= 900 && activeIdx < 0 ? <div className={`name-container ${activeIdx === 0 ? "active" : ""}`} onClick={() => changeActiveIdx(0)}>
                                <p>Servicii terapie, profilaxie, igienizare	</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 1 ? <div className={`name-container ${activeIdx === 1 ? "active" : ""}`} onClick={() => changeActiveIdx(1)}>
                                <p>Estetică dentară</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 2 ? <div className={`name-container ${activeIdx === 2 ? "active" : ""}`} onClick={() => changeActiveIdx(2)}>
                                <p>Odontoterapie (Plombe)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 3 ? <div className={`name-container ${activeIdx === 3 ? "active" : ""}`} onClick={() => changeActiveIdx(3)}>
                                <p>Ortodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 4 ? <div className={`name-container ${activeIdx === 4 ? "active" : ""}`} onClick={() => changeActiveIdx(4)}>
                                <p>Servicii endodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 5 ? <div className={`name-container ${activeIdx === 5 ? "active" : ""}`} onClick={() => changeActiveIdx(5)}>
                                <p>Servicii chirurgie mica, urgențe</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 6 ? <div className={`name-container ${activeIdx === 6 ? "active" : ""}`} onClick={() => changeActiveIdx(6)}>
                                <p>Pedodonție</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 7 ? <div className={`name-container ${activeIdx === 7 ? "active" : ""}`} onClick={() => changeActiveIdx(7)}>
                                <p>Servicii chirurgie omf (medic specialist)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}
                            
                            {window.innerWidth <= 900 && activeIdx < 8 ? <div className={`name-container ${activeIdx === 8 ? "active" : ""}`} onClick={() => changeActiveIdx(8)}>
                                <p>Servicii parodontologie</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 9 ? <div className={`name-container ${activeIdx === 9 ? "active" : ""}`} onClick={() => changeActiveIdx(9)}>
                                <p>Servicii implantologie</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 10 ? <div className={`name-container ${activeIdx === 10 ? "active" : ""}`} onClick={() => changeActiveIdx(10)}>
                                <p>Servicii protetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 11 ? <div className={`name-container ${activeIdx === 11 ? "active" : ""}`} onClick={() => changeActiveIdx(11)}>
                                <p>Servicii estetica dentara</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}

                            {window.innerWidth <= 900 && activeIdx < 12 ? <div className={`name-container ${activeIdx === 12 ? "active" : ""}`} onClick={() => changeActiveIdx(12)}>
                                <p>Servicii pedodontie (Tratamente copii)</p>
                                <FontAwesomeIcon icon={faAngleRight as IconProp} className="angle-icon" />
                            </div> : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Treatments;